import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Select, DatePicker, Form } from "antd";
import { GetSalaryslip, getEmployee, setTableData, setTableDataouter, GetSalaryslipforouttertable } from "../store/dataSlice";
import { toggleNewDialog, toggleDrower } from "../store/stateSlice";

const { Option } = Select;

const SalaryslipFilter = () => {
    const location = useLocation();

    const dispatch = useDispatch();
    const { pageIndex, pageSize, total } = useSelector(
        (state) => state.salaryslip.data.outertableData
    );
    const dialog = useSelector((state) => state.salaryslip.state.newDialog);
    const [form] = Form.useForm();


    const [filters, setFilters] = useState({ MONTH: null, YEAR: null });



    const onChange = (date, dateString) => {
        let [year, month] = dateString.split("-");
        month = parseInt(month, 10);
        setFilters({ ...filters, MONTH: month, YEAR: year });

    };



    const fetchData = useCallback(() => {
        const updatedFilters = {
            ...filters,
            pageIndex,
            pageSize,
        };
        dispatch(GetSalaryslipforouttertable(updatedFilters))

    }, [dispatch, pageIndex, pageSize]);

    useEffect(() => {
        fetchData();
    }, [pageIndex, pageSize]);


    const handleSubmit = () => {

        const updatedFilters = {
            ...filters,
            pageIndex: 1,
            pageSize,
        };
        dispatch(setTableDataouter({ pageIndex: 1, pageSize }));
        dispatch(GetSalaryslipforouttertable(updatedFilters));
    };


    useEffect(() => {
        dispatch(setTableDataouter({ pageIndex: 1, pageSize }));
        dispatch(toggleNewDialog(false));
        dispatch(toggleDrower(false));


    }, [location.pathname]);




    const fetchData4 = useCallback(() => {
        const updatedFilters = {
            pageIndex,
            pageSize,
        };
        dispatch(GetSalaryslipforouttertable(updatedFilters))

    }, [dispatch, pageIndex, pageSize]);

    useEffect(() => {
        fetchData4();
    }, [dialog]);


    return (
        <>
            <style>
                {`
                    .custom-select .ant-select-selector {
                        height: auto !important;
                        min-height: 2.75rem;
                        max-height: 5rem;
                        overflow-y: auto;
                    }
                    .custom-select .ant-select-selection-item {
                        white-space: normal;
                    }
                    .custom-select .ant-select-dropdown {
                        max-height: 15rem;
                        overflow-y: auto;
                    }
                `}
            </style>
            <div className="filter mb-4">
                {dialog && (
                    <div className="p-4 bg-white rounded border border-dashed">
                        <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-4 md:col-span-1">
                                <label className="block mb-1 font-semibold text-gray-500">Month & Year</label>

                                <Form form={form} layout="vertical">
                                    <Form.Item
                                        name="year"
                                        rules={[{ required: true, message: 'Please select a year and month' }]}
                                    >
                                        <DatePicker className="w-full custom-select h-11" onChange={onChange} picker="month" />


                                    </Form.Item>
                                </Form>

                            </div>
                        </div>
                        <div className="flex items-end justify-end">
                            <div className="flex">
                                <Button
                                    type="default"
                                    className="mr-4 py-4 px-6 border border-blue-500"
                                    onClick={() => {
                                        setFilters({ MONTH: null, YEAR: null });
                                        dispatch(toggleNewDialog(false));
                                        dispatch(setTableDataouter({ pageIndex: 1, pageSize }));
                                    }}
                                >
                                    Clear
                                </Button>
                                <Button type="primary" className="py-4 px-6" onClick={handleSubmit}>
                                    Apply
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SalaryslipFilter;













