import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Select, DatePicker, Form } from "antd";
import {
  GetSalaryslip,
  setTableData,
  setTableDatadetail,
  setTableDataouter,
  GetSalaryslipforouttertable,
  getDetailsinner,
  getEmployee,
  getBranch,
  getDepartment,
  getDetailsInnerExcel,
} from "../store/dataSlice";
import { toggleNewDialog2, toggleDrower } from "../store/stateSlice";

const { Option } = Select;

const SalaryslipFilter = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.salaryslip.data.detailTable
  );
  const dialog = useSelector((state) => state.salaryslip.state.newDialog2);
  const [form] = Form.useForm();

  const [filters, setFilters] = useState({
    BRANCH_ID: null,
    DEPARTMENT_ID: null,
    EMP_ID: null,
  });

  const fetchData4 = useCallback(() => {
    const updatedFilters = {
      pageIndex,
      pageSize,
    };
    dispatch(getDetailsinner(updatedFilters));
    dispatch(getDetailsInnerExcel());
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData4();
  }, [dialog]);

  const branch = useSelector(
    (state) => state?.salaryslip?.data?.branchList?.data
  );
  const department = useSelector(
    (state) => state?.salaryslip?.data?.departmetList?.data
  );
  const employee = useSelector(
    (state) => state?.salaryslip?.data?.employeeIdList?.data
  );
  const fetchData2 = useCallback(() => {
    dispatch(getEmployee());
    dispatch(getBranch());
    dispatch(getDepartment());
  }, [dispatch]);

  useEffect(() => {
    fetchData2();
  }, []);

  const fetchData = useCallback(() => {
    const updatedFilters = {
      ...filters,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
        (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,

      pageIndex,
      pageSize,
    };

    const updatedExcelFilters = {
      ...filters,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
        (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,
    };
    dispatch(getDetailsinner(updatedFilters));

    dispatch(getDetailsInnerExcel(updatedExcelFilters));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const handleSubmit = () => {
    const updatedFilters = {
      ...filters,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
        (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,

      pageIndex: 1,
      pageSize,
    };

    const updatedExcelFilters = {
      ...filters,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
        (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,
    };
    dispatch(setTableDatadetail({ pageIndex: 1, pageSize }));
    dispatch(getDetailsinner(updatedFilters));

    dispatch(getDetailsInnerExcel(updatedExcelFilters));
  };

  useEffect(() => {
    dispatch(setTableDatadetail({ pageIndex: 1, pageSize }));
    dispatch(toggleNewDialog2(false));
  }, [location.pathname]);

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  return (
    <>
      <style>
        {`
                    .custom-select .ant-select-selector {
                        height: auto !important;
                        min-height: 2.75rem;
                        max-height: 5rem;
                        overflow-y: auto;
                    }
                    .custom-select .ant-select-selection-item {
                        white-space: normal;
                    }
                    .custom-select .ant-select-dropdown {
                        max-height: 15rem;
                        overflow-y: auto;
                    }
                `}
      </style>
      <div className="filter mb-4">
        {dialog && (
          <div className="p-4 bg-white rounded border border-dashed">
            <div className="grid grid-cols-4 gap-4  ">
              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Branch Name
                </label>
                <div className="">
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={filterOption}
                    className="w-full custom-select h-11"
                    placeholder="Select Branch"
                    onChange={(value) => handleChange("BRANCH_ID", value)}
                  >
                    {branch?.map((type) => (
                      <Select.Option key={type.ID} value={type.ID}>
                        {type.NAME}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Department Name
                </label>
                <div className="">
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={filterOption}
                    className="w-full custom-select h-11"
                    placeholder="Select Department"
                    onChange={(value) => handleChange("DEPARTMENT_ID", value)}
                  >
                    {department?.map((type) => (
                      <Select.Option key={type.ID} value={type.ID}>
                        {type.DEPARTMENT}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Employee Name
                </label>
                <div className="">
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={filterOption}
                    className="w-full custom-select h-11"
                    placeholder="Select Employee"
                    onChange={(value) => handleChange("EMP_ID", value)}
                  >
                    {employee?.map((type) => (
                      <Select.Option key={type.ID} value={type.ID}>
                        {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="flex items-end justify-end">
              <div className="flex">
                <Button
                  type="default"
                  className="mr-4 py-4 px-6 border border-blue-500"
                  onClick={() => {
                    setFilters({
                      BRANCH_ID: null,
                      DESIGNATION_ID: null,
                      EMP_ID: null,
                      FROM_DATE: null,
                      TO_DATE: null,
                    });
                    dispatch(toggleNewDialog2(false));
                    dispatch(setTableDatadetail({ pageIndex: 1, pageSize }));
                    // dispatch(getDetailsinner({ pageIndex: 1, pageSize }));
                  }}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  className="py-4 px-6"
                  onClick={() => {
                    handleSubmit(filters);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SalaryslipFilter;
